import { Component, Input, OnChanges } from '@angular/core';
import {
  ActivateMandateResponse,
  Mandate,
  MandateDataType,
  MandateStatus,
} from '@app/modules/customer-zone/consumption/models/mandates.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Observable } from 'rxjs';
import { AppConfig } from '@app/shared/utils/app-config';
import { MandatesService } from '@app/modules/customer-zone/consumption/services/mandates/mandates.service';
import * as moment from 'moment';

interface MandateErrorStatus {
  noMandate?: boolean;
  expired?: boolean;
  granularity?: boolean;
  requested?: boolean;
  expiringSoon?: boolean;
}

@Component({
  selector: 'app-fluvius-mandates',
  templateUrl: './fluvius-mandates.component.html',
  styleUrls: ['./fluvius-mandates.component.scss'],
})
export class FluviusMandatesComponent implements OnChanges {
  @Input() eliqAccessRights: EliqAccessRights;
  @Input() mandates: Mandate[];
  @Input() reference: string;

  readonly mandateStatus = MandateStatus;
  readonly energyType = EnergyType;
  readonly granularity = MandateDataType;

  mandatesByEnergy: Array<Mandate[]>;
  energyMandateErrorStatus: {
    electricity: MandateErrorStatus;
    gas: MandateErrorStatus;
  } = { electricity: null, gas: null };

  fluviusLink$: Observable<ActivateMandateResponse>;

  constructor(public mandatesService: MandatesService, public facade: MainFacade, public conf: AppConfig) {}

  ngOnChanges() {
    if (this.eliqAccessRights?.profileByDeliveryPointReference) {
      const eans: string[] = Object.keys(this.eliqAccessRights?.profileByDeliveryPointReference);
      if (!eans.length || !this.mandates) {
        return;
      }
      if (this?.mandates?.length) {
        this.mandates = this.mandates.filter((mandate: Mandate) => eans.includes(mandate.ean));
        const missingEAN: string[] = eans.filter(
          (ean: string) => !this.mandates.some((m: Mandate): boolean => ean === m.ean)
        );
        const missingMandate: Mandate[] = this.mapMandateArray(missingEAN);
        this.mandates = [...this.mandates, ...missingMandate];
      } else {
        this.mandates = this.mapMandateArray(eans);
      }

      this.mandatesByEnergy = this.splitMandateByEnergy(this.mandates);
      this.fluviusLink$ = this.facade.getShortLinkMandateActivation();
    }
  }

  private mapMandateArray(eans: string[]): Mandate[] {
    return eans.map((ean: string) => ({
      ean,
      startOn: null,
      endOn: null,
      energyType: this.eliqAccessRights.profileByDeliveryPointReference[ean].energyType,
      reference: this.facade.state$.value.reference,
      status: MandateStatus.finished,
      dataServiceType: null,
      active: false,
    }));
  }

  private splitMandateByEnergy(mandates: Mandate[]): Array<Mandate[]> {
    if (!this.mandates.length) {
      return [];
    }

    // sort by dates and give first mandate only
    return [this.prepareMandates(mandates, EnergyType.ELECTRICITY), this.prepareMandates(mandates, EnergyType.GAS)];
  }

  private prepareMandates(mandates: Mandate[], energyType: EnergyType): Mandate[] {
    const preparedMandates = this.sortMandates(this.filterMandtesByEnergy(mandates, energyType));
    this.checkMandateErrors(preparedMandates, energyType);

    return preparedMandates;
  }

  private filterMandtesByEnergy(mandates: Mandate[], energyType: EnergyType): Mandate[] {
    return [...(mandates.filter((mandate) => mandate.energyType === energyType) || [])];
  }

  private checkMandateErrors(mandates: Mandate[], energyType: EnergyType): void {
    const mandateErrorStatus = (this.energyMandateErrorStatus[energyType.toLowerCase()] = {} as MandateErrorStatus);

    if (!mandates.length) {
      // empty mandate array
      mandateErrorStatus.noMandate = true;
    } else {
      const mandate = mandates[0];

      // no active mandate
      if ([MandateStatus.finished, MandateStatus.rejected].includes(mandate.status)) {
        mandateErrorStatus.noMandate = true;

        return;
      }

      // expiring soon
      if (mandate.expiringSoon) {
        mandateErrorStatus.expiringSoon = true;

        return;
      }

      // wrong energy granularity
      if (
        (mandate.energyType === EnergyType.ELECTRICITY && mandate.dataServiceType !== MandateDataType.quarterHour) ||
        (mandate.energyType === EnergyType.GAS &&
          ![MandateDataType.day, MandateDataType.hour].includes(mandate.dataServiceType))
      ) {
        mandateErrorStatus.granularity = true;

        return;
      }

      // expired date
      const today = moment();
      if (
        moment(mandate.endOn).isBefore(today) ||
        moment(mandate.startOn).isAfter() ||
        moment(mandate.expirationDate).isBefore(today)
      ) {
        mandateErrorStatus.expired = true;

        return;
      }

      // requested mandate
      if (mandate.status === MandateStatus.requested) {
        mandateErrorStatus.requested = true;

        return;
      }
    }

    // no error found
    this.energyMandateErrorStatus[energyType.toLowerCase()] = null;
  }

  private sortMandates(mandates: Mandate[]): Mandate[] {
    // sort by "expirationDate" after that "endOn" and at the end "startOn"
    return mandates
      .sort(
        (mandate1: Mandate, mandate2: Mandate) =>
          this.sortMandatesByDateAttribute(mandate1, mandate2, 'expirationDate') ||
          this.sortMandatesByDateAttribute(mandate1, mandate2, 'endOn') ||
          this.sortMandatesByDateAttribute(mandate1, mandate2, 'startOn')
      )
      .slice(0, 1);
  }

  private sortMandatesByDateAttribute(mandate1: Mandate, mandate2: Mandate, dateAttribute: string): number {
    return new Date(mandate2[dateAttribute]).getTime() - new Date(mandate1[dateAttribute]).getTime();
  }
}
