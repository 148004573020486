import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnergyUnit, VersionSize } from '@app/shared/models/units.interface';
import { Chart, ChartConfiguration, ChartDataset, ChartType } from 'chart.js';
import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import { switchMap, filter, takeUntil, catchError, map } from 'rxjs';
import { chartConfig } from '../../components/chart/config/chart';
import { EnergyType, Granularity, PeakVolume, RegisterTypeColor } from '../../models/consumption.interface';
import { TitleCasePipe } from '@angular/common';
import { VolumesService } from '../../services/volumes/volumes.service';
import zoomPlugin from 'chartjs-plugin-zoom';
import { MandatesService } from '../../services/mandates/mandates.service';
import { DeliveryPoint, UserType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { Mandate, MandateDataType, MandateStatus } from '../../models/mandates.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { Meter } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { Paths } from '@app/core/models/paths';
import moment from 'moment';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';
Chart.register(zoomPlugin);

export interface UserData {
  reference: string;
  siteId: string;
  deliveryPoint: string;
  from: string;
}

@Component({
  selector: 'app-power-peak-graph',
  templateUrl: './power-peak-graph.component.html',
  styleUrls: ['./power-peak-graph.component.scss'],
})
export class PowerPeakGraphComponent implements OnInit, OnDestroy {
  public loading = true;
  public locale: string;

  // Chart variables
  public barChartLabels = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartDataError = false;
  public barColor;

  public sites$;
  public activeSite$;
  public activeSiteId: string;
  public activeReference: string;
  public meterOptions: { id: string; label: string }[] = [];

  public userType: UserType;
  public versionSize = VersionSize;
  public deliveryPoint$: BehaviorSubject<any | null> = new BehaviorSubject(null);
  public deliveryPoints: DeliveryPoint[];

  public barChartData: ChartDataset[] = [
    {
      data: [],
      label: 'Piekvermogen',
      backgroundColor: RegisterTypeColor.POWER_PEAK,
      hoverBackgroundColor: RegisterTypeColor.POWER_PEAK,
      borderRadius: 10,
      borderSkipped: 'bottom',
      barThickness: 'flex',
    },
  ];

  public barChartOptions: ChartConfiguration['options'];
  public userData: UserData = {} as UserData;

  private notifier$ = new Subject<void>();
  private minimumLoadTime;
  private peakVolumes: PeakVolume[];
  private maxVolume = 0;

  constructor(
    private facade: MainFacade,
    private volumesService: VolumesService,
    private route: ActivatedRoute,
    private router: Router,
    private titlecasePipe: TitleCasePipe,
    private mandatesService: MandatesService
  ) {}

  ngOnInit(): void {
    this.barChartOptions = this.getDefaultChartOptions() as ChartConfiguration['options'];
    this.minimumLoadTime = new Promise((resolve) => setTimeout(resolve, 1500));
    this.setLocale();

    this.facade.contracts$
      .pipe(
        filter((contracts: ContractDtoCuzoApi[]) => !!contracts),
        takeUntil(this.notifier$),
        switchMap((contracts: ContractDtoCuzoApi[]) =>
          this.mandatesService.getMandatesByReference(this.facade.state$.value.reference).pipe(
            catchError((err) => of([])),
            filter((mandates) => mandates !== null),
            map((mandates) => ({ contracts, mandates }))
          )
        ),
        map(({ contracts, mandates }) => {
          const powerPeakMandates: Mandate[] = mandates.filter(
            (mandate) =>
              mandate.status === MandateStatus.approved &&
              mandate.energyType === EnergyType.ELECTRICITY &&
              mandate.dataServiceType === MandateDataType.quarterHour
          );
          return { contracts, powerPeakMandates };
        }),
        filter(({ contracts, powerPeakMandates }) => powerPeakMandates.length > 0),
        switchMap(({ contracts, powerPeakMandates }) => {
          const site: Site = this.facade.state$.value.activeSite;
          this.activeReference = this.facade.state$.value.reference;
          this.activeSiteId = site?.id;
          this.sites$ = this.facade.sites$;
          this.activeSite$ = this.facade.activeSite$;
          this.userData.siteId = this.activeSiteId;
          this.userData.from = moment(powerPeakMandates[0].startOn).startOf('month').toDate().toISOString();
          return forkJoin(
            contracts.map((contract: ContractDtoCuzoApi) =>
              this.facade
                .loadDeliveryPoint(this.activeReference, site, contract, true)
                .pipe(map((meter: Meter[]): DeliveryPoint => this.facade.remodelDeliveryPointResponse(meter, contract)))
            )
          );
        })
      )
      .subscribe((dps: DeliveryPoint[]) => {
        this.deliveryPoints = dps;
        const deliveryPoints = dps.filter((dp) => dp.energy === EnergyType.ELECTRICITY);
        this.meterOptions = [];
        deliveryPoints.forEach((dp) => {
          this.meterOptions.push({ id: dp.reference, label: dp.meterNumber });
        });
        this.userData.deliveryPoint = this.deliveryPoints[0].reference;
        this.userData.reference = this.activeReference;
        this.deliveryPoint$.next(dps[0]);
      });

    this.deliveryPoint$
      .pipe(
        takeUntil(this.notifier$),
        filter((deliveryPoint) => !!deliveryPoint),
        switchMap((deliveryPoint) => {
          this.userData.deliveryPoint = deliveryPoint.reference;
          return this.volumesService.getPowerPeak$(
            this.userData,
            Granularity.MONTH,
            moment().add('1', 'month').startOf('month').toISOString()
          );
        })
      )
      .subscribe(
        async (data: PeakVolume[]) => {
          if (data?.length !== 0) {
            this.peakVolumes = data;
            this.createChart(this.peakVolumes);

            await this.minimumLoadTime;
            this.loading = false;
          } else {
            this.goBack();
          }
        },
        (err) => (this.barChartDataError = true)
      );
  }

  public goBack() {
    this.router.navigate([Paths.consumptions]);
  }

  public switchSite(siteId: string) {
    this.facade.updateActiveSite(siteId);
  }

  public switchDeliveryPoint(deliveryPointRef: string) {
    const deliveryPoint = this.deliveryPoints.find((dp) => dp.reference === deliveryPointRef);
    this.deliveryPoint$.next(deliveryPoint);
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  private setLocale(): void {
    this.locale = this.facade.translate.currentLang + '-BE';
    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier$)).subscribe((value) => {
      this.analytics(this.router.url);
      this.locale = value.lang + '-BE';
      this.createChart(this.peakVolumes);
    });
  }

  private createChart(data: PeakVolume[]) {
    this.barChartLabels = [];
    this.barChartData[0].data = [];
    const annotations = [];
    this.maxVolume = this.maxValue(data.map((volume: PeakVolume) => volume.value) as []);

    data.map((volume: PeakVolume, index: number) => {
      this.barChartData[0].data.push(volume.value);
      const date = new Date(volume.previousMeteringDate);
      this.barChartLabels.push(this.titlecasePipe.transform(formatDate(date, 'MMM', this.locale)));
      // Create object for vertical line after every december
      if (date.getMonth() === 11) {
        annotations.push(...this.createAnnotation(date, index));
      }
    });

    this.barChartData[0].label = this.facade.translate.instant(`components.consumption.powerPeak.powerPeakName`);

    this.barChartOptions.plugins['annotation'] = {
      annotations: annotations as any,
    };

    // Prepare scale for scrolling
    let startWithBar = 0;
    if (data.length > 12) {
      startWithBar = data.length - 12;
    }
    this.barChartOptions.scales = {
      ...this.barChartOptions.scales,
      x: {
        min: startWithBar,
        max: startWithBar + 12,
        grid: {
          display: false,
        },
      },
    };
  }

  private createAnnotation(date: Date, barOrder: number) {
    const color = '#B7CBD3';
    return [
      {
        type: 'line',
        borderColor: color,
        color,
        borderWidth: 2,
        scaleID: 'x',
        borderDash: [6, 6],
        borderDashOffset: 0,
        value: barOrder + 0.5,
      },
      {
        type: 'label',
        content: date.getFullYear(),
        adjustScaleRange: true,
        font: {
          size: 12,
        },
        color,
        xValue: barOrder,
        yValue: this.maxVolume + 0.5,
      },
      {
        type: 'label',
        content: date.getFullYear() + 1,
        adjustScaleRange: true,
        color,
        font: {
          size: 12,
        },
        xValue: barOrder + 1,
        yValue: this.maxVolume + 0.5,
      },
    ];
  }

  private maxValue(data: []) {
    return data && data.length > 0 ? data.reduce((a, b) => (a > b ? a : b)) : 0;
  }

  private analytics(path: string = null) {
    this.facade.analytics.push(
      {
        event: 'pageview',
        component: { name: 'MyEnergy-consumption-power-peak' },
        page: {
          path: `${path || this.router.url}`,
          phase: 'care',
          category: 'cuzo',
          subCategory: `power peak consumption - cuzo`,
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
        eliqAccessRights: this.facade?.state$?.value?.eliqAccessRights,
        contracts: this.facade?.state$?.value?.contracts,
      }
    );
  }

  private getDefaultChartOptions() {
    const unit = EnergyUnit.kw;

    const defaultValue = {
      ...chartConfig.barChartOptions,
      barThickness: 4,
      ...{
        plugins: {
          ...chartConfig.barChartOptions.plugins,
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
          },
          tooltip: {
            ...chartConfig.barChartOptions.plugins.tooltip,
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += Math.round(context.parsed.y * 100) / 100 + ' ' + unit;
                }
                return label;
              },
              labelTextColor: () => '#7B7B7B',
            },
            titleFont: {
              size: 12,
            },
            bodyFont: {
              size: 12,
            },
          },
        },
      },
    };

    defaultValue.scales.y.ticks.callback = (val) => `${val} ${unit}`;
    return defaultValue;
  }
}
