<ng-container *ngIf="mandates?.length">
  <div class="px-8 xl:max-w-screen-xl xl:w-full xl:mx-auto mt-12">
    <h3 class="font-medium text-grey-dark60 text-base mb-4">
      {{ 'components.fluviusMandates.mandatesPerEAN' | translate }}
    </h3>
    <div class="card-primary">
      <ng-container *ngFor="let mandates of mandatesByEnergy">
        <div *ngIf="mandates.length" class="first:mb-16 last:mb-0">
          <span class="inline-block border-b border-grey w-full mb-8 pb-4">
            {{ 'components.fluviusMandates.mandatesTitle.' + (mandates[0].energyType | lowercase) | translate }}
          </span>
          <div
            *ngFor="let mandate of mandates; let i = index"
            class="{{ i + 1 < mandates.length ? 'border-b border-grey pb-8 mb-8 relative ' : '' }}sm:flex"
          >
            <div class="pl-14 relative grow-0 shrink-0 basis-2/5">
              <img
                *ngIf="mandate?.energyType"
                src="./assets/img/icons/standalone/grey/meter-{{ mandate?.energyType?.toLowerCase() }}.svg"
                class="w-10 left-0 absolute"
                alt=""
              />
              <span class="inline-block w-full mb-2">
                {{ 'components.fluviusMandates.meter.' + mandate.energyType.toLowerCase() | translate }}
              </span>
              <span class="inline-block w-full font-medium"> {{ mandate.ean }} </span>
            </div>
            <div class="mt-8 sm:mt-0 pl-14 relative">
              <ng-container
                *ngIf="!energyMandateErrorStatus[mandate.energyType.toLowerCase()]; else mandateErrorStatus"
              >
                <img
                  src="./assets/img/icons/standalone/dark-green/check_mark.svg"
                  class="w-10 left-0 absolute"
                  alt=""
                />
                <span *ngIf="mandate?.startOn && mandate?.endOn; else activeNoDate" class="text-success-lighter">
                  {{
                    'components.fluviusMandates.active'
                      | translate
                        : {
                            startDate: mandate.startOn | date : 'dd.MM.yyyy',
                            endDate: mandate.endOn | date : 'dd.MM.yyyy'
                          }
                  }}
                </span>
                <ng-template #activeNoDate>
                  {{ 'components.fluviusMandates.activeNoDate' | translate }}
                </ng-template>

                <span *ngIf="mandate?.dataServiceType" class="inline-block w-full text-sm">
                  {{ 'components.fluviusMandates.granularity.description' | translate }}
                  <strong>
                    {{
                      'components.fluviusMandates.granularity.period.' + mandate?.dataServiceType.toLowerCase()
                        | translate
                    }}
                  </strong>
                </span>
              </ng-container>

              <ng-template #mandateErrorStatus>
                <ng-container *ngIf="energyMandateErrorStatus[mandate.energyType.toLowerCase()].noMandate">
                  <span class="inline-block w-full text-sm text-primary">
                    {{ 'components.fluviusMandates.errorStatus.noMandate' | translate }}
                  </span>
                  <ng-container
                    [ngTemplateOutlet]="this.conf.config.production ? fluviusCTA : mockMandateInTest"
                    [ngTemplateOutletContext]="{ text: 'components.fluviusMandates.activate' | translate }"
                  >
                  </ng-container>
                  <app-tooltip
                    class="absolute top-0 right-0 align-top"
                    [tooltip]="'components.fluviusMandates.tooltip.mandateStatus' | translate"
                  ></app-tooltip>
                </ng-container>

                <ng-container *ngIf="energyMandateErrorStatus[mandate.energyType.toLowerCase()].expiringSoon">
                  <span class="inline-block w-full text-sm text-primary">
                    {{ 'components.fluviusMandates.errorStatus.expiringSoon' | translate }}
                  </span>
                  <ng-container
                    [ngTemplateOutlet]="this.conf.config.production ? fluviusCTA : mockMandateInTest"
                    [ngTemplateOutletContext]="{ text: 'components.fluviusMandates.extend' | translate }"
                  >
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="energyMandateErrorStatus[mandate.energyType.toLowerCase()].expired">
                  <span class="inline-block w-full text-sm text-primary">
                    {{ 'components.fluviusMandates.errorStatus.expired' | translate }}
                  </span>
                </ng-container>

                <ng-container *ngIf="energyMandateErrorStatus[mandate.energyType.toLowerCase()].granularity">
                  <span class="inline-block w-full text-sm text-primary">
                    {{ 'components.fluviusMandates.errorStatus.granularity' | translate }}
                  </span>
                  <ng-container
                    [ngTemplateOutlet]="this.conf.config.production ? fluviusCTA : mockMandateInTest"
                    [ngTemplateOutletContext]="{
                      text: 'components.fluviusMandates.disclaimer.ctaEnableQuartHourMandate' | translate
                    }"
                  >
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="energyMandateErrorStatus[mandate.energyType.toLowerCase()].requested">
                  <img src="./assets/img/tevc-monitoring/clock2.svg" class="w-10 left-0 absolute" alt="" />
                  <span class="text-grey-dark60">
                    {{ 'components.fluviusMandates.errorStatus.mandateRequested' | translate }}
                  </span>
                </ng-container>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #fluviusCTA let-text="text">
  <ng-container *ngIf="fluviusLink$ | async as fluviusLink">
    <a
      [href]="fluviusLink?.fluviusUrl"
      target="_blank"
      class="cursor-pointer btn-text-primary inline-block w-full font-medium"
    >
      {{ text }}
    </a>
  </ng-container>
</ng-template>

<ng-template #mockMandateInTest let-text="text">
  <ng-container *ngIf="fluviusLink$ | async as fluviusLink">
    <form (ngSubmit)="this.facade.createAllMockMandates(fluviusLink.fluviusUrl)" method="post">
      <button class="cursor-pointer btn-text-primary inline-block w-full font-medium" type="submit">
        {{ text }}
      </button>
    </form>
  </ng-container>
</ng-template>
