<div class="card rounded-4xl border border-grey bg-white !py-8" appSlideToggle [collapsed]="true">
  <div class="flex items-center cursor-pointer" #toggler>
    <div class="toggle-icon"></div>
    <h3
      class="text-base text-grey-dark font-medium ml-3"
      [analyticsEvent]="'personalBanner:click-more-info:' + moreInvoicesEventLabel"
    >
      {{ title | translate }} ({{ invoices.length + numberOfPaymentPlanSlicesToPay }})
    </h3>
  </div>
  <div #contentBox>
    <div #content>
      <div class="divide-y divide-grey pt-12">
        <ng-container *ngFor="let invoice of invoices">
          <ng-container *ngIf="viewType === PaymentBalanceViewType.PAY_MORE_INVOICES">
            <ng-container *ngTemplateOutlet="openInvoiceItem; context: { $implicit: invoice }"> </ng-container>
          </ng-container>
          <ng-container *ngIf="viewType === PaymentBalanceViewType.REFUND_MORE_INVOICES">
            <ng-container *ngTemplateOutlet="refundInvoiceItem; context: { $implicit: invoice }"> </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-content select="[papur]"></ng-content>
      <a
        routerLink="/invoices"
        class="text-center btn-text inline-block w-full mt-4 text-primary font-medium cursor-pointer mb-8"
        [analyticsEvent]="'personalBanner:click-more-info:all-invoices'"
      >
        {{ 'pages.dashboard.paymentBalance.paymentInformation.seeAllInvoices' | translate }}
      </a>
    </div>
  </div>
</div>

<ng-template #openInvoiceItem let-invoiceData>
  <div class="grid grid-cols-12 gap-4 items-center py-8 first:pt-0">
    <div class="col-span-6 sm:col-span-5">
      <app-invoice-type [invoice]="invoiceData"> </app-invoice-type>
    </div>
    <div class="col-span-4 sm:col-span-3">
      <div class="flex flex-col w-full text-2xl font-medium text-center">
        {{ invoiceData.amount.amountWithVat | currency : 'EUR' : 'symbol' : '1.2-2' }}
        <span *ngIf="(facade.accessRights$ | async)?.excludeVAT" class="block text-xs text-center text-grey-dark60">{{
          'general.inclVAT' | translate
        }}</span>
      </div>
    </div>
    <div class="hidden sm:block sm:col-span-3">
      <app-invoice-pay-online [billingDetails]="billingDetails" [invoice]="invoiceData"> </app-invoice-pay-online>
    </div>
    <div class="col-span-2 sm:col-span-1 text-right">
      <app-invoice-actions [billingDetails]="billingDetails" [invoice]="invoiceData" [accessRights]="accessRights">
      </app-invoice-actions>
    </div>
  </div>
</ng-template>

<ng-template #refundInvoiceItem let-invoiceData>
  <div class="grid grid-cols-12 gap-4 items-center py-8 first:pt-0">
    <div class="col-span-6 sm:col-span-4">
      <app-invoice-type [invoice]="invoiceData"> </app-invoice-type>
    </div>
    <div class="hidden sm:block sm:col-span-4">
      <div class="col-span-5 flex flex-col justify-center items-baseline font-normal text-sm">
        <app-invoice-status [invoice]="invoiceData" [icon]="false"> </app-invoice-status>
      </div>
    </div>

    <div class="col-span-4 sm:col-span-3">
      <span class="inline-block w-full text-2xl font-medium text-center">
        {{ invoiceData.amount.amountWithVat | currency : 'EUR' : 'symbol' : '1.2-2' }}
      </span>
    </div>
    <div class="col-span-2 sm:col-span-1 text-right">
      <app-invoice-actions
        [billingDetails]="billingDetails"
        [invoice]="invoiceData"
        [accessRights]="accessRights"
      ></app-invoice-actions>
    </div>
    <div class="col-span-full inline-block sm:hidden font-normal text-sm">
      <app-invoice-status [invoice]="invoiceData" [icon]="false"> </app-invoice-status>
    </div>
  </div>
</ng-template>
