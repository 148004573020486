import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { ContactDetails, ContactDetailsPerReference, Countries } from '../../models/contactDetails.interface';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import countriesJson from '../../../../../../assets/json/countries.json';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  public countries = [];
  public contactDetails: ContactDetailsPerReference = {};

  constructor(private http: HttpClient) {}

  public getContactDetails(reference: string): Observable<ContactDetails> {
    if (
      this.contactDetails !== undefined &&
      this.contactDetails[reference] !== undefined &&
      !this.contactDetails[reference].locked
    ) {
      return of(this.contactDetails[reference]);
    }

    return this.getContactDetailsFromApi(reference).pipe(
      tap((data) => {
        this.contactDetails[reference] = data;
      })
    );
  }

  public getCountries(lang: string): Observable<Countries> {
    if (this.countries && this.countries[lang]) {
      return of(this.countries[lang]);
    }

    return of(countriesJson).pipe(
      map(({ countries }) => {
        let options = {};
        countries.map(({ iso, fr, nl }) => (options = { ...options, [iso]: lang === 'fr' ? fr : nl }));
        this.countries[lang] = options;
        return options;
      })
    );
  }

  public getContactDetailsFromApi(reference): Observable<ContactDetails> {
    return this.http.get<ContactDetails>(`/v1/customers/${reference}/contact`);
  }

  public updateContactDetails(reference, contactDetails: ContactDetails): Observable<void> {
    return this.http.put<void>(`/v1/customers/${reference}/contact`, contactDetails).pipe(
      catchError((e) => throwError(e)) // Todo react to errors.
    );
  }
}
