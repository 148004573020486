import { Component, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-move',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss'],
})
export class MoveComponent implements OnInit, OnDestroy {
  public moveUrl: string;
  private notifier: Subject<void> = new Subject();

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.analytics();
    this.facade.utils.setPageTitle('pages.move.pageTitle');
    this.moveUrl = `${this.facade.utils.getMoveUrl()}/user/login`;

    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe((event: LangChangeEvent) => {
      this.analytics();
      this.moveUrl = `${this.facade.utils.getMoveUrl()}/user/login`;
    });
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'move - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
