<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mb-16">
    <h2 class="mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block md:my-12">
      {{ 'pages.move.title' | translate }}
    </h2>
    <div class="md:grid md:grid-cols-12 gap-16">
      <div class="md:col-span-8">
        <div class="card-primary">
          <div class="-m-8 md:-m-16">
            <img class="rounded-t-4xl w-full" src="./assets/img/move.jpg" alt="Move" />
          </div>
          <h2 class="font-medium text-base text-grey-dark mt-16 md:mt-32 mb-8">
            {{ 'pages.move.intro' | translate }}
          </h2>
          <ul class="list-disc list-outside ml-8">
            <li class="text-primary">
              <span class="text-grey-dark">{{ 'pages.move.requirements.item1' | translate }}</span>
            </li>
            <li class="text-primary">
              <span class="text-grey-dark">{{ 'pages.move.requirements.item2' | translate }}</span>
            </li>
            <li class="text-primary">
              <span class="text-grey-dark">{{ 'pages.move.requirements.item3' | translate }}</span>
            </li>
          </ul>
          <p class="mt-16 mb-8">{{ 'pages.move.outro' | translate }}</p>
          <a class="btn btn-primary" [href]="moveUrl" [analyticsEvent]="'move:click:start-move'">
            {{ 'pages.move.button.move' | translate }}
          </a>
        </div>
      </div>

      <div class="mt-16 md:mt-0 md:col-span-4">
        <app-tip></app-tip>
      </div>
    </div>
  </div>
</div>
