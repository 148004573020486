export enum AlertType {
  danger = 'danger',
  successBackground = 'success-background',
  success = 'success',
  successThumbUp = 'success-thumbs-up',
  error = 'error',
  info = 'info',
  warning = 'warning',
  warningBlue = 'warning-blue',
}

export interface Alert {
  type: AlertType;
  reason?: string;
  content: AlertContent;
}

export interface AlertContent {
  title?: string;
  titleKey?: string;
  message?: string;
  messageKey?: string;
  messageInterpolateParams?: { [key: string]: string | number | boolean };
  link?: string;
  linkLabel?: string;
  centerAlertIcon?: boolean;
  closeButton?: boolean;
}
