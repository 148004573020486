export enum Paths {
  reports = 'reports',
  myEv = 'my-ev',
  invoices = 'invoices',
  contact = 'contact',
  consumptions = 'consumptions',
  myConsumption = 'my-consumption',
  estimatedIndex = 'estimated-index',
  production = 'production',
  powerPeak = 'power-peak',
  greenCertificates = 'green-certificates',
  advancePayment = 'advance-payment',
  preferences = 'preferences',
  references = 'references',
  unauthorized = 'unauthorized',
  sponsorship = 'sponsorship',
  selfBilling = 'self-billing',
  other = 'other',
  house = 'house',
  move = 'move',
  root = '/',
}
