<ng-container>
  <div class="inline-block w-full font-medium">
    <span
      class="break-words md:whitespace-normal"
      [ngClass]="{
        elec_icon: invoice?.energy === EnergyType.ELECTRICITY,
        gas_icon: invoice?.energy === EnergyType.GAS,
        dual_icon: invoice?.energy === EnergyType.DUAL
      }"
    >
      {{ 'components.invoices.invoiceTypes.' + invoice?.type | translate }}
    </span>
    <ng-container *ngIf="showInvoiceNumber && invoice?.invoiceNumber">
      <span class="hidden lg:inline-block relative top-[0.3rem] mr-[0.6rem] w-px h-6 bg-grey-dark ml-[0.3rem]"> </span>
      <span class="hidden lg:inline-block">{{ invoice?.invoiceNumber }}</span>
    </ng-container>
  </div>
  <span class="inline-block w-full text-xs">
    {{ 'general.from' | translate }} {{ invoice?.startDate | date : 'dd/MM/yyyy' }} {{ 'general.until' | translate }}
    {{ invoice?.endDate | date : 'dd/MM/yyyy' }}
  </span>
</ng-container>
