<div
  *ngIf="paymentSlices?.length"
  class="relative rounded-2xl bg-yellow/20 border-2 border-yellow p-8 mt-8"
>
  <span class="absolute left-8 top-0 -translate-y-1/2 px-6 py-2 bg-yellow rounded-4xl font-medium">
    <img ngSrc="./assets/img/clipboard.svg" height="20" width="19" class="inline-block align-[-4px] mr-2">
    {{ 'general.paymentPlan' | translate }}
  </span>
  <div *ngFor="let slice of paymentSlices" class="relative grid grid-cols-1 gap-0 lg:grid-cols-12 lg:gap-16 pt-16 items-center">
    <div class="col-span-5">
      <h2 class="font-sans font-medium text-base">
        {{ 'components.paymentPlan.breakdown.headers.monthlyPayment.long' | translate }}
        {{ slice.dueDate | date:'MMMM':undefined:facade.translate.currentLang + '-BE' | titlecase | lowercase }}
      </h2>

      <div *ngIf="!payWithDirectDebit">
        <div class="bullet-style">
          <span class="inline-block w-full">
            {{ 'components.paymentPlan.overview.upcomingPayment.iban' | translate }}
            {{ 'general.companyIBAN' | translate }}
          </span>
        </div>
        <div class="bullet-style">
          <span class="inline-block w-full">
            {{ 'components.paymentPlan.overview.upcomingPayment.structuredCommunication' | translate }}
            <span class="font-medium inline-block w-full text-primary">{{ structuredCommunication }}</span>
          </span>
        </div>
      </div>

      <div *ngIf="payWithDirectDebit" class="bullet-style">
        <span class="inline-block w-full">
          {{ 'components.paymentPlan.overview.upcomingPayment.paymentAccount' | translate }}
        </span>
        <span class="inline-block w-full">
          {{ 'components.paymentPlan.overview.upcomingPayment.iban' | translate }}
          {{ 'general.companyIBAN' | translate }}
        </span>
      </div>
    </div>
    <div class="col-span-4 bullet-style no-style">
      <span class="inline-block w-full">
        {{ 'components.paymentPlan.overview.upcomingPayment.' + (payWithDirectDebit ? 'payViaDirectDebit' : 'payViaBankTransfer') | translate }}
      </span>
      <span class="font-bold inline-block w-full">
        {{ slice?.dueDate | date:'dd.MM.yy':undefined:facade.translate.currentLang + '-BE' }}
      </span>
    </div>
    <div class="col-span-3 text-2xl font-medium absolute right-0 top-0 lg:relative">
      {{ slice?.balance | currency: 'EUR' }}
    </div>
  </div>
  <a
    routerLink="/invoices/payment-plan"
    class="text-center btn-text inline-block w-full mt-12 text-primary font-medium cursor-pointer mb-8"
    [analyticsEvent]="'personalBanner:click-more-info:payment-plan'"
  >
    {{ 'pages.dashboard.openPaymentPlan.myPaymentPlan' | translate }}
  </a>
</div>
