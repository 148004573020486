<div class="card mt-16 md:mt-0">
  <div class="flex justify-center">
    <span class="flex h-24 w-24 items-center rounded-full bg-white align-top">
      <img class="mx-auto scale-110" alt="tip icon" src="./assets/img/icons/standalone/red/bulb.svg" />
    </span>
  </div>
  <h4 class="font-medium text-grey-dark text-2xl mt-8 mb-8 text-center">
    {{ 'pages.move.sidebar.tip.title' | translate }}
  </h4>
  <p class="tip__content text-sm" [innerHTML]="'pages.move.sidebar.tip.content' | translate"></p>
</div>
