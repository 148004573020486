/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccessRightsCuzoApi {
  accessIndex56?: boolean;
  accessAdvance?: boolean;
  accessBuildingInfo?: boolean;
  accessContracts?: boolean;
  accessEnergyInsights?: boolean;
  accessInjection?: boolean;
  accessInvoices?: boolean;
  accessMeterReading?: boolean;
  accessMove?: boolean;
  accessMyConsumption?: boolean;
  accessMyOtherProducts?: boolean;
  accessPaymentMethod?: boolean;
  accessSponsorship?: boolean;
  accessTevc?: boolean;
  activateBankTransfer?: boolean;
  activateDom?: boolean;
  activateDomv?: boolean;
  bindClientRef?: boolean;
  businessChat?: boolean;
  businessConsents?: boolean;
  businessContactDetails?: boolean;
  createAccount?: boolean;
  excludeVAT?: boolean;
  modifyAdvance?: boolean;
  modifyContactDetails?: boolean;
  modifyInvoicingMethod?: boolean;
  modifyRefundAccount?: boolean;
  payOnline?: boolean;
  personalConsents?: boolean;
  personalContactDetails?: boolean;
  privateChat?: boolean;
  seeGenericMessage?: boolean;
  showZoomit?: boolean;
  switchClientRef?: boolean;
  viewBalance?: boolean;
  viewInvoices?: boolean;
  viewPaymentPlan?: boolean;
  viewRegulDetails?: boolean;
  viewTariffCard?: boolean;
  viewUpsell?: boolean;
}

export interface AccountCreationResponseCuzoApi {
  isAllowed?: boolean;
}

export interface AddressCuzoApi {
  boxNumber?: string;
  city?: string;
  country?: string;
  formattedAddress?: string;
  inseeCode?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
}

export interface AdvanceChangeRefusedResponseCuzoApi {
  unallowedReasons?: string[];
}

export interface BadPayerDetailCuzoApi {
  badPayer?: boolean;
  customerReference?: string;
  oldReference?: string;
  reasons?: string[];
  reasonsDetails?: string[];
  statusDate?: string;
}

export interface BillingDetailsCuzoApi {
  address?: AddressCuzoApi;
  bankAccount?: string;
  bankAccountBIC?: string;
  email?: string;
  invoiceDeliveryChannel?: string;
  invoiceSendingMethodLocked?: boolean;
  paymentFrequency?: string;
  paymentMethod?: BillingDetailsPaymentMethodEnumCuzoApi;
  paymentMethodLocked?: boolean;
  refundAccount?: string;
  refundAccountBIC?: string;
}

export enum BillingDetailsPaymentMethodEnumCuzoApi {
  BANK_DOMICILIATION = 'BANK_DOMICILIATION',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  BANK_TRANSFER = 'BANK_TRANSFER',
  DIRECT_DEBIT_AND_BANK_TRANSFER = 'DIRECT_DEBIT_AND_BANK_TRANSFER',
}

export interface BreakdownCuzoApi {
  category?: string;
  /** @format double */
  percentage?: number;
  /** @format double */
  value?: number;
}

export interface BundleDetailsCuzoApi {
  alias?: string;
  cgv?: I18NViewCuzoApi;
  code?: string;
  commercialCode?: string;
  commercialLinks?: LinksCuzoApi;
  description?: I18NViewCuzoApi;
  name?: I18NViewCuzoApi;
  picture?: string;
}

export interface ContactCuzoApi {
  acceptAdvertisement?: boolean;
  acceptMail?: boolean;
  address?: AddressCuzoApi;
  bankAccount?: string;
  birthDate?: string;
  civility?: string;
  companyNumber?: string;
  contactName?: string;
  email?: string;
  emailLogin?: string;
  fax?: string;
  firstName?: string;
  language?: string;
  lastName?: string;
  locked?: boolean;
  mobile?: string;
  phone?: string;
  socialReason?: SocialReasonCuzoApi;
  type?: string;
  usableForMarketing?: boolean;
  vatNumber?: string;
}

export interface ContactRequestCuzoApi {
  email?: string;
  name?: string;
  phone?: string;
}

export interface ContractDtoCuzoApi {
  i18n?: JsonNodeCuzoApi;
  bundleDetails?: BundleDetailsCuzoApi;
  code?: string;
  deliveryPointComponent?: string;
  deliveryPointReference?: string;
  /** @format date-time */
  forseenEndDate?: string;
  indeterminateDuration?: boolean;
  injectionTariff?: ContractTariffCuzoApi;
  meterNumber?: string;
  name?: string;
  /** @format date-time */
  startDate?: string;
  /** @format double */
  subscription?: number;
  tariff?: ContractTariffCuzoApi;
  tariffCards?: TariffCardCuzoApi[];
  type?: ContractDtoTypeEnumCuzoApi;
}

export enum ContractDtoTypeEnumCuzoApi {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
  ASSISTANCE = 'ASSISTANCE',
  BOILER_MAINTENANCE = 'BOILER_MAINTENANCE',
  BOILER_REPAIR = 'BOILER_REPAIR',
  BOILER_MAINTENANCE_ONE_SHOT = 'BOILER_MAINTENANCE_ONE_SHOT',
  OTHER = 'OTHER',
}

export interface ContractTariffCuzoApi {
  /** @format double */
  mono?: number;
  /** @format double */
  nightTimeOnly?: number;
  /** @format double */
  offPeak?: number;
  /** @format double */
  peak?: number;
  /** @format date-time */
  socialTariffEndDate?: string;
  /** @format date-time */
  socialTariffStartDate?: string;
  type?: string;
}

export interface CreateContactCuzoApi {
  address?: AddressCuzoApi;
  bankAccount?: string;
  birthDate?: string;
  civility?: string;
  email?: string;
  emailLogin?: string;
  fax?: string;
  firstName?: string;
  language?: string;
  lastName?: string;
  mobile?: string;
  phone?: string;
}

export interface CreateMonthlyAdvanceCuzoApi {
  amount?: string;
  budgetMeter?: boolean;
  consumptionMono?: string;
  consumptionNighttime?: string;
  consumptionOffpeak?: string;
  consumptionPeak?: string;
  deliveryPoint?: string;
  invoicingMode?: string;
  origin?: string;
  paymentFrequency?: string;
  /** @format int32 */
  status?: number;
  type?: string;
}

export interface CreateSepaDTOCuzoApi {
  address?: AddressCuzoApi;
  bankAccount?: string;
  bankAccountBIC?: string;
  directDebit?: boolean;
  directDebitAndBankTransfer?: boolean;
  email?: string;
  firstName?: string;
  language?: string;
  lastName?: string;
  refund?: boolean;
  zoomit?: boolean;
}

export interface DeleteMeteringCuzoApi {
  /** @format date-time */
  date?: string;
  gsrn?: string;
}

export interface DeliveryPointConfigurationCuzoApi {
  energy?: string;
  meterNumber?: string;
  registers?: RegisterCuzoApi[];
  smartMeter?: boolean;
}

export interface DeliveryPointInformationCuzoApi {
  accessToMyEnergyRecommendation?: boolean;
}

export interface DiscountCuzoApi {
  currency?: string;
  discount?: number;
}

export interface EnergyUsageCategoriesCuzoApi {
  accuracy?: string;
  breakdown?: BreakdownCuzoApi[];
  from?: string;
  to?: string;
  /** @format double */
  totalValue?: number;
  unit?: string;
}

export interface EstimateCuzoApi {
  timeType?: TimeTypeWithValueCuzoApi[];
}

export interface I18NViewCuzoApi {
  en?: string;
  fr?: string;
  nl?: string;
}

export interface IndexCuzoApi {
  register?: string;
  /** @format double */
  value?: number;
}

export interface InstallmentCuzoApi {
  /** @format double */
  amount?: number;
  endPeriod?: string;
  startPeriod?: string;
}

export interface InvoiceAmountCuzoApi {
  currency?: string;
  /** @format double */
  value?: number;
  /** @format double */
  valueVATExcl?: number;
  /** @format double */
  vatAmount?: number;
}

export interface InvoiceAmountV2CuzoApi {
  amountVat?: number;
  amountWithVat?: number;
  amountWithoutVat?: number;
  currency?: string;
}

export interface InvoiceBalanceCuzoApi {
  communication?: string;
  currency?: string;
  /** @format double */
  overdue?: number;
  /** @format double */
  total?: number;
  /** @format double */
  value?: number;
}

export interface InvoiceModelCuzoApi {
  amount?: InvoiceAmountCuzoApi;
  /** @format double */
  balance?: number;
  customerReference?: string;
  dueDate?: string;
  endDate?: string;
  energy?: string;
  invoiceDate?: string;
  payNxtInfo?: InvoicePayNxtInfoCuzoApi;
  paymentDate?: string;
  paymentMethod?: string;
  reference?: string;
  startDate?: string;
  status?: string;
  structuredCommunication?: string;
  type?: InvoiceModelTypeEnumCuzoApi;
}

export enum InvoiceModelTypeEnumCuzoApi {
  ANNULATION_CORR_REG = 'ANNULATION_CORR_REG',
  ANNULATION_AVANCE = 'ANNULATION_AVANCE',
  EXTRA = 'EXTRA',
  CORRECTION_REGULE = 'CORRECTION_REGULE',
  REGULE = 'REGULE',
  AVANCE = 'AVANCE',
  ANNULATION_REGULE = 'ANNULATION_REGULE',
  PAPUR = 'PAPUR',
}

export interface InvoiceModelV2CuzoApi {
  amount?: InvoiceAmountV2CuzoApi;
  balance?: number;
  cancelledBy?: string[];
  cancelledInvoiceNumbers?: string[];
  customerReference?: string;
  domRejectionReason?: string;
  /** @format date */
  dueDate?: string;
  dunningLocked?: boolean;
  /** @format date */
  endDate?: string;
  energy?: string;
  hasDetailedDocument?: boolean;
  /** @format date */
  invoiceDate?: string;
  invoiceNumber?: string;
  /** @format date */
  paymentDate?: string;
  paymentLink?: string;
  refundRejectionReason?: string;
  /** @format date */
  startDate?: string;
  status?: InvoiceModelV2StatusEnumCuzoApi;
  structuredCommunication?: string;
  type?: InvoiceModelV2TypeEnumCuzoApi;
}

export enum InvoiceModelV2StatusEnumCuzoApi {
  DUE = 'DUE',
  PART_STILL_DUE = 'PART_STILL_DUE',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
  PART_OVERDUE = 'PART_OVERDUE',
  DIRECT_DEBIT_PLANNED = 'DIRECT_DEBIT_PLANNED',
  DIRECT_DEBIT_REJECTED = 'DIRECT_DEBIT_REJECTED',
  REFUND_PLANNED = 'REFUND_PLANNED',
  PART_REFUND = 'PART_REFUND',
  REFUND = 'REFUND',
  NO_REFUND_ACCOUNT = 'NO_REFUND_ACCOUNT',
  REFUND_ON_HOLD = 'REFUND_ON_HOLD',
  CONVERTED_IN_PAYMENT_PLAN = 'CONVERTED_IN_PAYMENT_PLAN',
  PAID_THROUGH_PAYMENT_PLAN = 'PAID_THROUGH_PAYMENT_PLAN',
  CANCELLATION = 'CANCELLATION',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN',
}

export enum InvoiceModelV2TypeEnumCuzoApi {
  ANNULATION_CORR_REG = 'ANNULATION_CORR_REG',
  ANNULATION_AVANCE = 'ANNULATION_AVANCE',
  EXTRA = 'EXTRA',
  CORRECTION_REGULE = 'CORRECTION_REGULE',
  REGULE = 'REGULE',
  AVANCE = 'AVANCE',
  ANNULATION_REGULE = 'ANNULATION_REGULE',
  PAPUR = 'PAPUR',
}

export interface InvoicePayNxtInfoCuzoApi {
  checkoutUrl?: string;
  status?: string;
}

export interface InvoiceResponseCuzoApi {
  balance?: InvoiceBalanceCuzoApi;
  invoiceTypes?: InvoiceResponseInvoiceTypesEnumCuzoApi[];
  invoiceYears?: number[];
  invoices?: InvoiceModelCuzoApi[];
  /** @format int64 */
  totalInvoices?: number;
}

export enum InvoiceResponseInvoiceTypesEnumCuzoApi {
  ANNULATION_CORR_REG = 'ANNULATION_CORR_REG',
  ANNULATION_AVANCE = 'ANNULATION_AVANCE',
  EXTRA = 'EXTRA',
  CORRECTION_REGULE = 'CORRECTION_REGULE',
  REGULE = 'REGULE',
  AVANCE = 'AVANCE',
  ANNULATION_REGULE = 'ANNULATION_REGULE',
  PAPUR = 'PAPUR',
}

export interface InvoiceResponseV2CuzoApi {
  billingDetails?: BillingDetailsCuzoApi;
  invoiceTypes?: InvoiceResponseV2InvoiceTypesEnumCuzoApi[];
  invoiceYears?: number[];
  invoices?: InvoiceModelV2CuzoApi[];
  /** @format int64 */
  totalInvoices?: number;
}

export enum InvoiceResponseV2InvoiceTypesEnumCuzoApi {
  ANNULATION_CORR_REG = 'ANNULATION_CORR_REG',
  ANNULATION_AVANCE = 'ANNULATION_AVANCE',
  EXTRA = 'EXTRA',
  CORRECTION_REGULE = 'CORRECTION_REGULE',
  REGULE = 'REGULE',
  AVANCE = 'AVANCE',
  ANNULATION_REGULE = 'ANNULATION_REGULE',
  PAPUR = 'PAPUR',
}

export interface InvoicesDetailsCuzoApi {
  invoices?: InvoiceModelV2CuzoApi[];
  totalAmount?: number;
  totalBalance?: number;
}

export type JsonNodeCuzoApi = object;

export interface LinksCuzoApi {
  fr?: string;
  nl?: string;
}

export interface MeterCuzoApi {
  indexes?: IndexCuzoApi[];
  meterNumber?: string;
}

export interface MeteringCuzoApi {
  context?: string;
  /** @format date-time */
  indexDate?: string;
  indexes?: IndexCuzoApi[];
  status?: string;
}

export interface ModificationCallbackCuzoApi {
  errorCode?: string;
  message?: string;
  status?: ModificationCallbackStatusEnumCuzoApi;
}

export enum ModificationCallbackStatusEnumCuzoApi {
  OK = 'OK',
  KO = 'KO',
}

export interface MyEnergyFlowInformationRequestCuzoApi {
  deliveryPoints?: string[];
}

export interface MyEnergyFlowInformationResponseCuzoApi {
  deliveryPointsInformation?: Record<string, DeliveryPointInformationCuzoApi>;
}

export interface NoAccessReasonCuzoApi {
  genericMessageDescription?: BadPayerDetailCuzoApi;
  genericMessageReason?: string;
}

export interface OpenInvoicesCuzoApi {
  billingDetails?: BillingDetailsCuzoApi;
  due?: InvoicesDetailsCuzoApi;
  refund?: InvoicesDetailsCuzoApi;
}

export interface PaymentPlanCuzoApi {
  amount?: number;
  balance?: number;
  billingDetails?: BillingDetailsCuzoApi;
  /** @format date */
  endDate?: string;
  /** @format int32 */
  paidSlices?: number;
  paymentId?: string;
  paymentPlanFees?: PaymentPlanFeesCuzoApi;
  relatedInvoiceIds?: string[];
  slices?: SliceCuzoApi[];
  /** @format date */
  startDate?: string;
  /** @format int32 */
  totalSlices?: number;
  upcomingPayment?: UpcomingPaymentCuzoApi;
}

export interface PaymentPlanFeesCuzoApi {
  amount?: number;
}

export interface PersonalConsentCuzoApi {
  consentTypeCode?: string;
  id?: string;
  state?: boolean;
  updateAt?: string;
  updateBy?: string;
  updateChannel?: string;
  updateInfo?: string;
}

export interface PostUserProfilesRequestCuzoApi {
  loginEmail?: string;
  reference?: string;
  sendingMode?: string;
  token?: string;
}

export interface PriceSimulationRequestCuzoApi {
  bundleCode?: string;
  metadata?: JsonNodeCuzoApi;
  promoCode?: string;
  /** @format date */
  signatureDate?: string;
}

export interface ProductCuzoApi {
  ean?: string;
  name?: string;
  type?: string;
}

export interface ProvidedIndexCuzoApi {
  /** @format int32 */
  advanceStatus?: number;
  /** @format date-time */
  indexDate?: string;
  indexes?: IndexCuzoApi[];
}

export interface PutUserProfilesRequestCuzoApi {
  label?: string;
}

export interface ReadBestAdvanceCuzoApi {
  alreadyInvoicedInstallments?: InstallmentCuzoApi;
  /** @format double */
  currentMonthlyDeposit?: number;
  /** @format int32 */
  meterReadingMonth?: number;
  /** @format int32 */
  remainingInvoicesBeforeSettlementInvoice?: number;
  /** @format double */
  suggestedDeposit?: number;
  useCaseMyEnergy?: ReadBestAdvanceUseCaseMyEnergyEnumCuzoApi;
  yearlyEstimateBasedOnIndex?: EstimateCuzoApi;
  /** @format double */
  yearlyEstimatedAmountBasedOnIndex?: number;
}

export enum ReadBestAdvanceUseCaseMyEnergyEnumCuzoApi {
  NO_RESPONSE = 'NO_RESPONSE',
  MODIFICATION_ONGOING = 'MODIFICATION_ONGOING',
  ALREADY_OK = 'ALREADY_OK',
  ALREADY_DECREASED = 'ALREADY_DECREASED',
  DECREASE_TOO_IMPORTANT = 'DECREASE_TOO_IMPORTANT',
  DECREASE_POSSIBLE = 'DECREASE_POSSIBLE',
  INCREASE_RECOMMENDED_AND_DECREASE_NOT_POSSIBLE = 'INCREASE_RECOMMENDED_AND_DECREASE_NOT_POSSIBLE',
  INCREASE_WITH_DECREASE_POSSIBLE = 'INCREASE_WITH_DECREASE_POSSIBLE',
}

export interface ReadInvoiceDataCuzoApi {
  address?: AddressCuzoApi;
  bankAccount?: string;
  bankAccountBIC?: string;
  billingBatch?: string;
  email?: string;
  invoiceDeliveryChannel?: string;
  invoiceSendingMethodLocked?: boolean;
  paymentFrequency?: string;
  paymentMethod?: ReadInvoiceDataPaymentMethodEnumCuzoApi;
  paymentMethodLocked?: boolean;
  refundAccount?: string;
  refundAccountBIC?: string;
}

export enum ReadInvoiceDataPaymentMethodEnumCuzoApi {
  BANK_DOMICILIATION = 'BANK_DOMICILIATION',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  BANK_TRANSFER = 'BANK_TRANSFER',
  DIRECT_DEBIT_AND_BANK_TRANSFER = 'DIRECT_DEBIT_AND_BANK_TRANSFER',
}

export interface ReadMeteringCuzoApi {
  context?: string;
  ean?: string;
  energy?: string;
  id?: string;
  indexDate?: string;
  meters?: MeterCuzoApi[];
  reference?: string;
  site?: string;
  status?: string;
}

export interface ReadMonthlyAdvanceCuzoApi {
  amount?: string;
  averagePeak?: string;
  brutoOfftakeMono?: string;
  brutoOfftakeOffPeak?: string;
  brutoOfftakePeak?: string;
  budgetMeter?: boolean;
  consumptionMono?: string;
  consumptionNighttime?: string;
  consumptionOffpeak?: string;
  consumptionPeak?: string;
  deliveryPoint?: string;
  /** @format date */
  effectiveDate?: string;
  flanders?: boolean;
  injectionMono?: string;
  injectionOffpeak?: string;
  injectionPeak?: string;
  invoicingMode?: string;
  minAmountAllowedIncVat?: number;
  paymentFrequency?: string;
  regularizationFrequency?: string;
  smartMeter?: boolean;
  /** @format double */
  solarPanelPower?: number;
  /** @format int32 */
  status?: number;
  type?: string;
  unallowedReasons?: string[];
  updateAllowed?: boolean;
}

export interface ReadVolumeCuzoApi {
  currentMeteringDate?: string;
  previousMeteringDate?: string;
  volumes?: VolumeCuzoApi[];
}

export interface RegisterCuzoApi {
  direction?: string;
  timeFrame?: string;
}

export interface RegistrationRequestCuzoApi {
  agentCode?: string;
  context?: string;
  id?: string;
  salesContext?: string;
  salesContextDetail?: string;
}

export interface SiteCuzoApi {
  apartmentNumber?: string;
  boxNumber?: string;
  building?: string;
  city?: string;
  country?: string;
  floorNumber?: string;
  formattedAddress?: string;
  id?: string;
  inseeCode?: string;
  label?: string;
  products?: ProductCuzoApi[];
  street?: string;
  streetName?: string;
  streetNumber?: string;
  town?: string;
  zipCode?: string;
}

export interface SiteSummaryCuzoApi {
  area?: string;
  boxNumber?: string;
  building?: string;
  buildingOccupant?: string;
  country?: string;
  formattedAddress?: string;
  hasSolarPanel?: boolean;
  historicalProducts?: ProductCuzoApi[];
  id?: string;
  products?: ProductCuzoApi[];
  solarInstallationDate?: string;
  solarPanelKva?: string;
  streetName?: string;
  streetNumber?: string;
  town?: string;
  zipCode?: string;
}

export interface SliceCuzoApi {
  amount?: number;
  balance?: number;
  /** @format date */
  dueDate?: string;
  /** @format int32 */
  sliceNumber?: number;
  status?: SliceStatusEnumCuzoApi;
}

export enum SliceStatusEnumCuzoApi {
  PAID = 'PAID',
  DUE = 'DUE',
  PART_DUE = 'PART_DUE',
  OVERDUE = 'OVERDUE',
  PART_OVERDUE = 'PART_OVERDUE',
}

export interface SocialReasonCuzoApi {
  code?: string;
  translations?: Record<string, string>;
}

export interface SponsorshipCuzoApi {
  recipients?: SponsorshipRecipientCuzoApi[];
  totalDiscount?: DiscountCuzoApi;
  /** @format int32 */
  totalRecipients?: number;
  yearlyDiscount?: DiscountCuzoApi;
  /** @format int32 */
  yearlyRecipients?: number;
}

export interface SponsorshipRecipientCuzoApi {
  discount?: DiscountCuzoApi;
  firstName?: string;
  invoiceNumber?: string;
  lastName?: string;
  /** @format date */
  signatureDate?: string;
}

export interface TariffCardCuzoApi {
  name?: string;
  type?: TariffCardTypeEnumCuzoApi;
  url?: string;
}

export enum TariffCardTypeEnumCuzoApi {
  PRODUCT = 'PRODUCT',
  TARIFF_SOCIAL = 'TARIFF_SOCIAL',
  INJECTION = 'INJECTION',
}

export interface TimeTypeWithValueCuzoApi {
  type?: string;
  /** @format double */
  value?: number;
}

export interface UpSellRequestCuzoApi {
  bundleCode?: string;
  contact?: ContactRequestCuzoApi;
  creationUser?: string;
  metadata?: JsonNodeCuzoApi;
  promoCode?: string;
  registration?: RegistrationRequestCuzoApi;
}

export interface UpcomingPaymentCuzoApi {
  amount?: number;
  balance?: number;
  /** @format date */
  dueDate?: string;
  overdueAmount?: number;
  /** @format int32 */
  sliceNumber?: number;
}

export interface UpdateMeteringCuzoApi {
  /** @format date-time */
  indexDate?: string;
  meters?: MeterCuzoApi[];
}

export interface UpdateMeteringResponseCuzoApi {
  alreadySubmittedIndexes?: IndexCuzoApi[];
  /** @format int32 */
  index?: number;
  message?: string;
  status?: string;
}

export interface UserAccountCreateRequestCuzoApi {
  email?: string;
  language?: string;
  redirectUrl?: string;
}

export interface UserProfileCreateCuzoApi {
  loginEmail?: string;
  reference?: string;
  sendingMode?: string;
  token?: string;
}

export interface UserProfileCustomerCuzoApi {
  accessRights?: AccessRightsCuzoApi;
  label?: string;
  reference?: string;
  /** @uniqueItems true */
  sites?: SiteCuzoApi[];
}

export interface UserProfileCuzoApi {
  companyName?: string;
  /** @uniqueItems true */
  customers?: UserProfileCustomerCuzoApi[];
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface VolumeCuzoApi {
  direction?: string;
  measurementNature?: string;
  quality?: string;
  register?: string;
  unit?: string;
  /** @format double */
  value?: number;
}
