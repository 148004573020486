import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {
  BillingDetails,
  BillingDetailsPerReference,
  BillingDetailsRequest,
} from '../../models/billingDetails.interface';
import { catchError, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private billingDetails: BillingDetailsPerReference = {};

  constructor(private http: HttpClient) {}

  public getBillingDetails(reference: string): Observable<BillingDetails> {
    if (
      this.billingDetails &&
      this.billingDetails[reference] &&
      !this.billingDetails[reference].paymentMethodLocked &&
      !this.billingDetails[reference].invoiceSendingMethodLocked
    ) {
      return of(this.billingDetails[reference]);
    }

    return this.getBillingDetailsFromApi(reference);
  }

  public updateBillingDetails(reference: string, billingDetails: BillingDetailsRequest): Observable<void> {
    return this.http
      .put<void>(`/v1/customers/${reference}/billing-details`, billingDetails)
      .pipe(catchError((e) => throwError(e)));
  }

  private getBillingDetailsFromApi(reference: string): Observable<BillingDetails> {
    return this.http.get<BillingDetails>(`/v1/customers/${reference}/billing-details`).pipe(
      tap((billingDetails: BillingDetails) => {
        this.billingDetails[reference] = billingDetails;
      })
    );
  }
}
