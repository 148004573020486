import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../../shared/utils/utils.service';
import { map, Observable } from 'rxjs';
import { ContentService } from '@app/shared/services/content/content.service';
import { Content, ContentDescription } from '@app/shared/models/content.interface';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface QuickLinksContentDescription extends ContentDescription {
  eventLabel: string;
}

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit {
  public content$: Observable<Content & { quickLinks: QuickLinksContentDescription[] }>;

  constructor(public utilsService: UtilsService, private contentService: ContentService) {}

  ngOnInit() {
    this.content$ = this.contentService.getActiveContent().pipe(
      map((content: Content) => {
        return {
          ...content,
          quickLinks: this.createTranslationKeyForEventLabel(content.quickLinks),
          conditionalQuickLinks:
            content.conditionalQuickLinks && this.createTranslationKeyForEventLabel(content.conditionalQuickLinks),
        };
      })
    );
  }

  private createTranslationKeyForEventLabel(quickLinks: ContentDescription[]): QuickLinksContentDescription[] {
    return quickLinks.map((link) => {
      const translationPathsParts = link.label.split('.');
      translationPathsParts.pop(); // Remove the last element from eventLabel array
      return { ...link, eventLabel: `${translationPathsParts.join('.')}.eventLabel` };
    });
  }
}

_('dynamicContent.b2c.quickLinks.item1.eventLabel');
_('dynamicContent.b2c.quickLinks.item2.eventLabel');
_('dynamicContent.b2c.quickLinks.item3.eventLabel');
_('dynamicContent.b2c.quickLinks.item4.eventLabel');
_('dynamicContent.b2b.quickLinks.item1.eventLabel');
_('dynamicContent.b2b.quickLinks.item2.eventLabel');
_('dynamicContent.b2b.quickLinks.item3.eventLabel');
_('dynamicContent.b2b.quickLinks.item4.eventLabel');
_('dynamicContent.b2c.conditionalQuickLinks.item1.eventLabel');
