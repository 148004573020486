import { EnergyType } from './consumption.interface';

export enum MandateStatus {
  requested = 'Requested',
  approved = 'Approved',
  rejected = 'Rejected',
  finished = 'Finished',
}
// 'day': customer can only use daily in request
// 'quarter-hour': customer can only request quarter-hourly (electricity) or hourly (gas) consumption
export enum MandateDataType {
  day = 'DAY',
  quarterHour = 'QUARTER_HOUR',
  quarterHour2 = 'QUARTER-HOUR',
  hour = 'HOUR',
}

export interface Mandate {
  reference: string;
  energyType: string | EnergyType;
  ean: string;
  active?: boolean;
  dataServiceType: MandateDataType;
  status?: MandateStatus;
  startOn: string; // YYYY-MM-DDTHH:MM:SS+offset
  endOn?: string; // YYYY-MM-DDTHH:MM:SS+offset
  effectiveEnd?: string; // YYYY-MM-DDTHH:MM:SS+offset
  expirationDate?: string; // YYYY-MM-DDTHH:MM:SS+offset
  expiringSoon?: boolean;
}

export interface ActivateMandateBody {
  returnUrlSuccess?: string;
  returnUrlFailed?: string;
}

export interface ActivateMandateResponse {
  status: string;
  shortUrlIdentifier: string;
  validTo: string;
  fluviusUrl?: string;
}
