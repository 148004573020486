import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveComponent } from './pages/move/move.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { TipComponent } from './components/tip/tip.component';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@NgModule({
  declarations: [MoveComponent, TipComponent],
  imports: [CommonModule, TranslateModule, RouterModule, SharedModule, AnalyticsModule],
})
export class MoveModule {}

_('pages.move.pageTitle');
