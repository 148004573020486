import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CHAT_ENV } from '@totalenergiescode/springbox-salesforce-chat';
import { AppModule } from './app/app.module';

fetch(`./assets/config/json/config.json`)
  .then((response) => response.json())
  .then((config) => {
    platformBrowserDynamic([[{ provide: CHAT_ENV, useValue: config.app.name }]])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
